<template>
  <v-container fluid class="container pa-5 mt-6">
    <v-row>
      <v-col
        v-for="({ ...attrs }, i) in stats"
        :key="i"
        cols="12"
        md="6"
        lg="3"
      >
        <v-skeleton-loader
          :loading="skeletonLoader"
          translate="trans"
          type="image"
        >
          <v-card outlined class="pa-3">
            <v-card-text>
              <table style="width: 100%">
                <tr>
                  <td>
                    <v-row class="primary--text">
                      <v-col align-self="center">
                        {{ attrs.title }}
                      </v-col>
                      <v-col class="float-end"> </v-col>
                    </v-row>
                    <v-row style="font-size: 32px">
                      <v-col>
                        {{ attrs.value }}
                      </v-col>
                    </v-row>
                  </td>
                  <td>
                    <v-icon
                      class="float-end primary--text"
                      style="font-size: 32px"
                    >
                      {{ attrs.icon }}
                    </v-icon>
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-card>
        </v-skeleton-loader>
      </v-col>

      <v-col cols="12" md="6">
        <v-skeleton-loader
          :loading="skeletonLoader"
          translate="trans"
          type="table"
        >
          <v-card outlined full-header class="mt-5">
            <v-card-title>
              <v-tabs v-model="tabs" background-color="transparent" class="">
                <span class="" style="align-self: center">
                  <v-btn icon :title="$t('refresh')" @click="getAppointment()">
                    <v-icon>mdi-cached</v-icon>
                  </v-btn>
                  {{ $t("appointments.appointmentStatus") }}:
                </span>
                <v-tab :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                  <v-icon :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">
                    mdi-clock
                  </v-icon>
                  {{ $t("patients.waitTab") }}
                </v-tab>
                <v-tab :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                  <v-icon :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">
                    mdi-heart
                  </v-icon>
                  {{ $t("patients.treatmentTab") }}
                </v-tab>
                <v-tab :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                  <v-icon :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">
                    mdi-close-thick
                  </v-icon>
                  {{ $t("patients.cancelTab") }}
                </v-tab>
                <v-tab :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                  <v-icon :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">
                    mdi-check-bold
                  </v-icon>
                  {{ $t("patients.doneTab") }}
                </v-tab>
              </v-tabs>
            </v-card-title>
            <v-tabs-items v-model="tabs" background-color="transparent">
              <v-tab-item v-for="n in 4" :key="n">
                <v-card-text>
                  <data-table-component
                    :headers="headers"
                    :items="appointments[tabs]"
                    :search="search"
                    :loading="loading"
                  />
                  <!-- <v-data-table
                    :headers="headers"
                    :items="appointments[tabs]"
                    :items-per-page="10"
                    :loading="loading"
                    :loading-text="$t('loading')"
                  >
                    <template v-slot:[`item.appointmentDate`]="{ item }">
                      {{ item.appointmentDate | moment("YYYY/MM/DD hh:mm A") }}
                    </template>
                  </v-data-table> -->
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader
          :loading="skeletonLoader"
          translate="trans"
          type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
        >
          <v-card outlined class="mt-5">
            <v-card-title>
              <div>
                <div class="font-weight-light">
                  {{ $t("appointments.quickbooking") }}
                </div>
                <div class="text-caption">
                  {{ $t("appointments.addPatientaAndAppointmentQuickly") }}
                </div>
              </div>
            </v-card-title>

            <v-card class="overflow-y-auto" :max-height="580" elevation="0">
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                    v-for="(doctor, i) in doctors"
                    :key="i"
                  >
                    <v-card outlined>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline"></div>
                          <v-list-item-title class="mb-1">
                            <v-icon small color="primary">mdi-doctor</v-icon>
                            {{ doctor.doctorName }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-icon small color="primary">mdi-auto-fix </v-icon>
                            {{ doctor.doctorSpecialization }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <v-icon small color="primary">mdi-phone</v-icon>
                            {{ doctor.doctorPhone }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                          size="80"
                          rounded
                          color="grey lighten-2"
                        >
                          <v-img
                            :lazy-src="require('@/assets/Doctor.png')"
                            aspect-ratio="1"
                            :src="
                              doctor.pictureUrl
                                ? doctor.pictureUrl
                                : require('@/assets/Doctor.png')
                            "
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <v-card-actions>
                        <v-btn
                          color="primary"
                          outlined
                          v-if="isInRole('20')"
                          rounded
                          text
                          min-width="80"
                          @click="bookingDialog(doctor)"
                        >
                          {{ $t("appointments.booking") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-dialog
      v-model="addAppointmentDialog"
      persistent
      max-width="600px"
      v-if="isInRole('20')"
    >
      <v-form
        ref="addAppointmentForm"
        v-model="addAppointmentValid"
        lazy-validation
      >
        <v-card>
          <v-toolbar color="primary darken-1" dark>
            <v-card-title>
              <span class="">{{
                $t("add") + " " + $t("appointments.appointment")
              }}</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <span>{{ $t("doctors.doctorName") }}:</span>
                  <v-chip class="ma-3" label>
                    <v-icon>mdi-doctor</v-icon>
                    {{ doctorName }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    v-model="appointmentData.patientGuid"
                    :rules="rules"
                    :items="patients"
                    :label="$t('patients.patientName')"
                    required
                    item-text="patientDisplayName"
                    item-value="guid"
                    prepend-icon="mdi-account-plus"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top v-if="isInRole('29')">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            v-if="isInRole('29')"
                            rounded
                            @click="patientDialog"
                            target="_blank"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("patients.patientRegistration") }}</span>
                      </v-tooltip>
                      <!-- <v-btn
                        icon
                        v-if="isInRole('29')"
                        rounded
                        :title="$t('patients.patientRegistration')"
                        @click="patientDialog"
                        target="_blank"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn> -->
                    </template>
                    <template v-slot:append-item>
                      <div v-intersect="endIntersect" />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="px-2">
                  <v-datetime-picker
                    :label="$t('appointments.appointmentDate')"
                    v-model="appointmentData.appointmentDate"
                    dateFormat="yyyy/MM/dd"
                    timeFormat="hh:mm aa"
                    :okText="$t('ok')"
                    :clearText="$t('cancel')"
                  >
                    <template v-slot:dateIcon>
                      <v-icon> mdi-calendar </v-icon>
                    </template>
                    <template v-slot:timeIcon>
                      <v-icon> mdi-clock </v-icon>
                    </template>
                  </v-datetime-picker>
                </v-col>

                <v-col cols="12" md="6" class="px-2">
                  <v-text-field
                    v-model="appointmentData.note"
                    :label="$t('notes')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn
              v-if="isInRole('20')"
              color="primary"
              elevation="0"
              @click="addAppointment"
              :disabled="!addAppointmentValid"
              :min-width="100"
            >
              <v-icon>mdi-content-save-outline</v-icon>
              {{ $t("save") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="closeAppointmentDialog">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-model="addPatientDialog"
      persistent
      max-width="600px"
      v-if="isInRole('29')"
    >
      <v-form ref="addPatientForm" v-model="addPatientValid" lazy-validation>
        <v-card>
          <v-toolbar color="primary darken-1" dark>
            <v-card-title>
              <span class="">{{
                $t("add") + " " + $t("patients.patient")
              }}</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="patient.patientFirstName"
                    :label="$t('patients.patientFirstName')"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="patient.patientMiddleName"
                    :label="$t('patients.patientMiddleName')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="patient.patientLastName"
                    :label="$t('patients.patientLastName')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="patient.patientFourName"
                    :label="$t('patients.patientFourName')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-select
                    v-model="countryGuid"
                    :items="countries"
                    :rules="rules"
                    :label="$t('country.countryName')"
                    item-text="countryName"
                    item-value="guid"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" sm="12">
                  <v-autocomplete
                    v-model="patient.cityGuid"
                    :rules="rules"
                    :items="filterCities"
                    :label="$t('city.cityName')"
                    item-text="cityName"
                    item-value="guid"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="patient.patientMobile"
                    :label="$t('phone')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="patient.patientDob"
                        :label="$t('patients.patientDob')"
                        :rules="rules"
                        persistent-hint
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="patient.patientDob"
                      :active-picker.sync="activePicker"
                      :rules="rules"
                      min="1750-01-01"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6" sm="12">
                  <v-select
                    :items="gender"
                    :label="$t('patients.patientGender')"
                    v-model="patient.patientGender"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="green darken-1"
              class="white--text"
              @click="addPatient"
              :disabled="!addPatientValid"
              :min-width="100"
            >
              <v-icon>mdi-content-save-outline</v-icon>
              {{ $t("save") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="closePatientDialog">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
// import MaterialStatsCard from "../components/base/MaterialStatsCard.vue";
// import MaterialCard from "../components/base/MaterialCard.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import i18n from "../i18n";

export default {
  components: {
    // MaterialStatsCard,
    // MaterialCard,
    DataTableComponent,
  },
  data: () => ({
    stats: [
      {
        subIcon: "mdi-magnify-plus-outline",
        subText: i18n.t("showMore"),
        subTextLink: "/patientschedule",
        color: "#FD9A13",
        icon: "mdi-calendar-multiple-check",
        title: i18n.t("appointments.appointments"),
        value: "0",
      },
      {
        subIcon: "mdi-magnify-plus-outline",
        subText: i18n.t("showMore"),
        subTextLink: "/patients",
        color: "primary",
        icon: "mdi-account-plus",
        title: i18n.t("patients.registaration"),
        value: "0",
      },
      {
        subIcon: "mdi-magnify-plus-outline",
        subText: i18n.t("showMore"),
        subTextLink: "/bills",
        color: "success",
        icon: "mdi-receipt",
        title: i18n.t("bills.bills"),
        value: "0",
      },
      {
        subIcon: "mdi-chart-bubble",
        subText: i18n.t("procedures.procedures"),

        color: "info",
        icon: "mdi-doctor",
        title: i18n.t("services.services"),
        value: "0",
      },
    ],
    headers: [
      {
        text: i18n.t("appointments.appointmentNumber"),
        value: "appointmentNumber",
      },
      { text: i18n.t("patients.patientName"), value: "patientName" },
      {
        text: i18n.t("patients.patientMobile"),
        value: "patientMobile",
      },
      { text: i18n.t("doctors.doctorName"), value: "doctorName" },
      {
        text: i18n.t("appointments.appointmentDate"),
        value: "appointmentDate",
        type: "date",
        format: "YYYY/MM/DD hh:mm A",
      },
    ],

    tabs: 0,
    loading: false,
    appointments: {
      0: [],
      1: [],
      2: [],
      3: [],
    },
    rules: [(value) => !!value || i18n.t("ThisFieldIsRequired")],
    doctors: [],
    patient: {},
    patients: [],
    patientPage: 0,
    appointmentData: {},
    addAppointmentDialog: false,
    addPatientDialog: false,
    addAppointmentValid: false,
    addPatientValid: false,
    doctorName: "",
    patientName: "",
    gender: [
      { text: i18n.t("patients.male"), value: 0 },
      { text: i18n.t("patients.female"), value: 1 },
    ],
    countryGuid: "",
    cityGuid: "",
    countries: [],
    cities: [],
    filterCities: [],
    menu: null,
    activePicker: null,
    skeletonLoader: false,
  }),
  created() {
    this.getDashboard();

    this.getAppointment();

    this.getDoctors();

    this.getCountries();
  },
  watch: {
    countryGuid(val) {
      if (val && val.cities) {
        this.filterCities = val.cities;
      }
    },
  },
  computed: {
    computedDateFormatted() {
      return this.$moment(this.patient.patientDob).format("YYYY-MM-DD");
    },
  },
  mounted() {
    // window.addEventListener("focus", this.pageFocused);
  },
  methods: {
    formatDate(date) {
      if (date != null && date != "") {
        var myDate = new Date(date);
        var d = myDate.getDate();
        var m = myDate.getMonth() + 1; //Month from 0 to 11
        var y = myDate.getFullYear();
        return (
          "" + y + "/" + (m <= 9 ? "0" + m : m) + "/" + (d <= 9 ? "0" + d : d)
        );
      }
      return "";
    },
    getDashboard() {
      this.skeletonLoader = true;
      this.$axios
        .get("Dashboard/Get")
        .then((response) => {
          this.stats[0].value =
            response.data.data.appointments.toLocaleString();
          this.stats[1].value = response.data.data.patients.toLocaleString();
          this.stats[2].value = response.data.data.bills.toLocaleString();
          this.stats[3].value = response.data.data.procedures.toLocaleString();
          this.skeletonLoader = false;
        })
        .finally(() => {});
    },

    getAppointment() {
      this.loading = true;
      this.$axios
        .get("Appointment/Get")
        .then((response) => {
          if (response.data.data.length > 0) {
            this.appointments[0] = response.data.data.filter(
              (m) => m.appointmentStatus === 0
            );
            this.appointments[1] = response.data.data.filter(
              (m) => m.appointmentStatus === 1
            );
            this.appointments[2] = response.data.data.filter(
              (m) => m.appointmentStatus === 2
            );
            this.appointments[3] = response.data.data.filter(
              (m) => m.appointmentStatus === 3
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDoctors() {
      this.$axios.get("Doctor/GetForAppointment").then((response) => {
        this.doctors = response.data.data;
      });
    },
    addPatient() {
      var val = this.$refs.addPatientForm.validate();
      if (!val) {
        return;
      }

      var data = new FormData();

      for (var key in this.patient) {
        if (this.patient[key]) data.append(key, this.patient[key]);
      }

      this.$axios
        .post("Patient/Add", data)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));

            var guid = response.data.data.guid;

            this.$axios.get("Patient/Get?guid=" + guid).then((response) => {
              if (response.data.status == "Successful") {
                this.patientPage += 1;
                this.patients.push(response.data.data.patient);
                this.appointmentData.patientGuid = guid;
                this.closePatientDialog();
              }
            });
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));

          console.log(e);
        });
    },
    getPatients() {
      this.$axios
        .get("Patient/GetByPages?skip=" + this.patientPage)
        .then((response) => {
          if (response.data.data.length > 0) {
            this.patientPage += response.data.data.length ?? 0;
            this.patients = [...this.patients, ...response.data.data];
          }
        })
        .finally(() => {});
    },

    endIntersect(_entries, _observer, isIntersecting) {
      if (isIntersecting == true) {
        this.getPatients();
      }
    },
    addAppointment() {
      var val = this.$refs.addAppointmentForm.validate();
      if (!val) {
        return;
      }

      var data = { ...this.appointmentData };
      data.appointmentDate = JSON.parse(
        JSON.stringify(data.appointmentDate.toLocaleString())
      );

      this.$axios
        .post("Appointment/Add", data)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));

            this.closeAppointmentDialog();
            this.getAppointment();
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
    },
    getCountries() {
      this.$axios.get("Country").then((response) => {
        this.countries = response.data.data;
        this.$axios.get("Setting/GetCountry").then((resp) => {
          this.countryGuid = resp.data.data.countryGuid;
          this.cityGuid = resp.data.data.cityGuid;
          setTimeout(() => {
            var allCities = this.countries.filter((o) => {
              return o.guid == this.countryGuid;
            })[0];
            if (allCities && allCities.cities) {
              this.filterCities = allCities.cities;
            }
          }, 500);
        });
      });
    },
    bookingDialog(doctor) {
      this.appointmentData = {};
      this.appointmentData.appointmentDate = new Date();
      this.appointmentData.doctorGuid = doctor.guid;
      this.doctorName = doctor.doctorName;
      this.getPatients();
      this.addAppointmentDialog = true;
    },
    closeAppointmentDialog() {
      this.appointmentData = {};
      this.selec;
      this.addAppointmentDialog = false;
    },
    closePatientDialog() {
      this.addPatientDialog = false;
      this.patient = {};
    },
    patientDialog() {
      this.patient = {};
      this.patient = {
        patientGender: 0,
        patientDob: this.$moment(new Date("1990-01-01")).format("YYYY-MM-DD"),
        cityGuid: this.cityGuid,
      };
      this.addPatientDialog = true;
    },
    // pageFocused() {
    //   if (this.addAppointmentDialog) {
    //     this.getPatients();
    //   }
    // },
  },
};
</script>

<style>
.v-application .text-caption {
  font-family: inherit !important;
}
</style>
